import {Firestore, collection, query, orderBy} from 'firebase/firestore'

/**
 * Retrieves a filtered list of insights based on user permissions.
 *
 * @param db - The Firestore instance.
 * @returns A computed reference to an array of filtered insights.
 */
export const useInsightsList = (db:Firestore) => {

  const permission = usePermissions()
  const insightsRef = useCollection(query(collection(db, 'insights'), orderBy('created', 'desc')), { ssrKey: 'insights-query' })

  return computed(() => insightsRef.value.filter(insight => permission.hasInsightPermission(insight as FsbdInsight)))

}
